<template>
	<div>
		<div style="width:100%;min-width: 100%;background: #eceff1;min-height: 100%;">
			<div style="background:#eceff1;" class="row justify-content-center my-3 py-3">
			    <div class="col-md-8">
			    	<div class="card">
			    		<div class="card-header">
			    			<div class="row align-items-center">
			    				<div class="col-3">
			    					<img src="@/assets/image/logo_dark.webp" class="img-fluid">
			    				</div>
			    				<div class="col-8">
			    					<h2 class="text-center font-weight-bold mb-0">Formulario solicitud de crédito hipotecario</h2>
			    				</div>
			    			</div>
			    		</div>
			    		<div class="card-body bg-light px-0">
			    			<div class="accordion-light" id="accordionExample">
			    				<div class="card overflow-hidden">
			    					<div class="card-header" id="headingSolicitante">
			    						<h5 class="mb-0">
			    							<a class="text-primary accordion-toggle w-100" type="button" data-toggle="collapse" data-target="#collapseSolicitante" aria-expanded="true" aria-controls="collapseSolicitante">
			    								<span>
				    								<div class="d-flex align-items-center justify-content-between cursor-pointer">
									                	<div class="d-flex align-items-center">
									                    	<span ng-if="userForm0.$valid" class="material-icons text-success">check_circle</span>
									                    	<span ng-if="userForm0.$invalid" class="material-icons">check_circle_outline</span>
									                    	Datos personales del solicitante
									                  	</div>
								            			<span class="material-icons float-right" ng-show="!hipotecarios.solicitante">keyboard_arrow_right</span>
								            			<span class="material-icons float-right" ng-show="hipotecarios.solicitante">expand_more</span>
								            		</div>
			    								</span>
			    							</a>
			    						</h5>
			    					</div>
			    					<div id="collapseSolicitante" class="card-collapse collapse show" aria-labelledby="headingSolicitante" data-parent="#accordionExample">
			    						<div class="card-body">
			    							
			    						</div>
			    					</div>
			    				</div>
			    			</div>
			    		</div>
			    	</div>
			    </div>
			</div>
		</div>
	</div>
</template>